<template>
  <div class="section" id="galeria">
    <div class="container text-center">
      <div class="title">
        <h2>Galería</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <carousel
            :per-page="1"
            :mouse-drag="false"
            :autoplay="true"
            :loop="true" paginationActiveColor="#0B2447" :paginationPadding="5" :paginationSize="7" :speed="1000">
            <slide v-for="index in 10" :key="index">
              <img class="d-block" :src="'img/galeria/' + index + '.jpg'" />
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
};
</script>
<style></style>
