<template>
  <div>
    <div class="page-header page-header-small">
      <video-background 
        src="vid/comedor.mp4"
        style="max-height: 450px; height: 120vh;"
        overlay="linear-gradient(180deg, rgba(2,0,36,0.7), rgba(0,32,87,0.4))"
      >
      </video-background>
      <!-- <parallax
        class="page-header-image"
        style="background-image: url('img/bg4.jpg'); opacity: 0.8;"
      >
      </parallax> -->
      <div class="content-center">
        <div class="container">
          <h1 class="title">La carta</h1>
          <div class="text-center">
            <!-- <h4>SUBTEXT</h4> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="container" style="margin-top: -50px !important;">

        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h3 class="title">Entrantes</h3>
            <div v-for="entrante in entrantes" :key="entrante.id">
              <h5 class="">{{ entrante.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ entrante.price }}</b>&nbsp;
                <img v-for="a in entrante.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Carnes</h4>
            <div v-for="carne in carnes" :key="carne.id">
              <h5 class="">{{ carne.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ carne.price }}</b>&nbsp;
                <img v-for="a in carne.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Pescados</h4>
            <div v-for="pescado in pescados" :key="pescado.id">
              <h5 class="">{{ pescado.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ pescado.price }}</b>&nbsp;
                <img v-for="a in pescado.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
            <h5><i>* Consulte previamente los pescados seleccionados directos de la lonja</i></h5>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Postres</h4>
            <div v-for="postre in postres" :key="postre.id">
              <h5 class="">{{ postre.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;">
                <b>{{ postre.price }}</b>&nbsp;
                <img v-for="a in postre.allergen" :key="a.id" :src="'icos/allergen/' + a + '.png'" height="40px" />
              </h5>
            </div>
          </div>
        </div>
        <hr style="width: 90%;">
        <div class="row" style="margin-top: -15px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 style="font-size: 17px;">SÁBADOS, DOMINGOS Y FESTIVOS MEDIODÍA</h4>
            <h5 style="font-size: 16px;"><i>Todos los precios son con IVA incluído. Pan y servicio 2€.</i></h5>
            <h5 style="font-size: 16px;"><i>No se servirán medias raciones.</i></h5>
          </div>
        </div>

        <!-- ALÉRGENOS -->
        <div class="row" style="margin-top: 20px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <img src="icos/allergen/huevos.png" height="60px" />
            <img src="icos/allergen/pescado.png" height="60px" />
            <img src="icos/allergen/moluscos.png" height="60px" />
            <img src="icos/allergen/cascara.png" height="60px" />
            <img src="icos/allergen/sesamo.png" height="60px" />
            <img src="icos/allergen/apio.png" height="60px" />
            <img src="icos/allergen/cacahuetes.png" height="60px" /><br>
            <img src="icos/allergen/lacteos.png" height="60px" />
            <img src="icos/allergen/mostaza.png" height="60px" />
            <img src="icos/allergen/crustaceos.png" height="60px" />
            <img src="icos/allergen/soja.png" height="60px" />
            <img src="icos/allergen/altramuces.png" height="60px" />
            <img src="icos/allergen/dioxido.png" height="60px" />
            <img src="icos/allergen/gluten.png" height="60px" />
            <!-- <h5 style="font-size: 16px;"><i>Todos los precios son con IVA incluído. Pan y servicio 2€.</i></h5> -->
          </div>
        </div>

      </div>
    </div> 
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'carta',
  bodyClass: 'landing-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Carta',
    meta: [
      { name: 'description', content: 'Ven y disfruta de nuestra carta variada' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Ven y disfruta de nuestra carta variada'},
      { property: 'og:description', content: 'Ven y disfruta de nuestra carta variada' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  data() {
    return {
      entrantes: [
        {
          title: 'Paleta de bellota 100% ibérico con tostas y tomate km0',
          price: '21€',
          allergen: ['gluten']
        },
        {
          title: 'Cecina de León con aliño de aceite de oliva virgen extra y lascas de Idiazabal',
          price: '16€',
          allergen: ['lacteos']
        },
        {
          title: 'Ventresca con peras al azafrán, pimientos de leña, brotes verdes y reducción de Pedro Ximénez',
          price: '16€',
          allergen: ['moluscos', 'pescado']
        },
        {
          title: 'Morcilla de Bakio a la brasa con crema de hongos',
          price: '10€',
          allergen: ['soja', 'lacteos']
        },
        {
          title: 'Croquetas caseras de ibéricos',
          price: '12€',
          allergen: ['lacteos', 'gluten', 'cascara']
        },
        {
          title: 'Zamburiñas a la plancha con polvo de jamón',
          price: '15€',
          allergen: ['moluscos']
        },
        {
          title: 'Pulpo laminado al carbón con fluido de patata y huevo',
          price: '22€',
          allergen: ['moluscos']
        },
        {
          title: 'Ragout de vieiras osmotizadas con lima',
          price: '18€',
          allergen: ['moluscos', 'lacteos']
        },
        {
          title: 'Tartar de atún con helado de lima y coco',
          price: '15€',
          allergen: ['gluten', 'lacteos', 'pescado', 'sesamo']
        },
        {
          title: 'Ensalada templada de bogavante con langostinos y vinagreta de pomelo (min. 2 pax.)',
          price: '28€',
          allergen: ['crustaceos']
        },
      ],
      pescados: [
        {
          title: 'Bakalao dos gustos confitado en aceite de humo',
          price: '17€',
          allergen: ['pescado']
        },
        {
          title: 'Kokotxas de Bakalao Giraldo con crema de nécoras y pimientos de leña',
          price: '18€',
          allergen: ['lacteos', 'pescado', 'gluten']
        },
      ],
      carnes: [
        {
          title: 'Txuletón Premium Deluxe madurado más de 45 días',
          price: '50€',
          allergen: []
        },
        {
          title: 'Solomillo braseado con hongos y manzana',
          price: '20€',
          allergen: ['gluten']
        },
        {
          title: 'Costillas asadas 15 horas a baja temperatura y glaseadas en su jugo',
          price: '18€',
          allergen: ['cascara']
        },
      ],
      postres: [
        {
          title: 'Coulant de chocolate con helado de vainilla',
          price: '7€',
          allergen: ['lacteos', 'gluten']
        },
        {
          title: 'Tarta de queso lentamente horneada',
          price: '7€',
          allergen: ['lacteos', 'gluten']
        },
        {
          title: 'Sorbete de limón al cava',
          price: '5€',
          allergen: ['lacteos', 'gluten']
        },
        {
          title: 'Tarta tres leches',
          price: '7€',
          allergen: ['lacteos', 'gluten']
        },
        {
          title: 'Tabla de quesos variados y frutos rojos',
          price: '7€',
          allergen: ['lacteos', 'gluten']
        },
      ]
    };
  }
};
</script>
<style></style>
