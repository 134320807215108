import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Carta from './pages/Carta.vue';
import Menu from './pages/Menu.vue';
import Hamburguesas from './pages/Hamburguesas.vue';
import Vinos from './pages/Vinos.vue';
import Contacto from './pages/Contacto.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import PageNotFound from './pages/PageNotFound.vue';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/carta',
      name: 'carta',
      components: { default: Carta, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/menu-fin-de-semana',
      name: 'menu-fin-de-semana',
      components: { default: Menu, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/hamburguesas',
      name: 'hamburguesas',
      components: { default: Hamburguesas, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/vinos',
      name: 'vinos',
      components: { default: Vinos, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contacto',
      name: 'contacto',
      components: { default: Contacto, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    { path: "*", component: PageNotFound }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
