<template>
  <div>
    <div class="page-header">
      <video-background 
        src="vid/terraza.mp4"
        style="max-height: 550px; height: 150vh;"
        overlay="linear-gradient(180deg, rgba(2,0,36,0.75), rgba(0,32,87,0.45))"
      >
      </video-background>
      <div class="content-center">
        <div class="container">
          <h2 class="title">Contacto</h2>
          
          <div class="content mt-5">
            <p class="category mt-5" style="text-transform: none !important;">Estamos en Pertxin Bidea 7, 48960, Usansolo.</p>
            <p class="category mt-4" style="text-transform: none !important;">Llámanos al <a class="text-white" href="tel:946042956" target="_blank">&nbsp;946 04 29 56</a>.</p>
            <p class="category mt-4" style="text-transform: none !important;">Escríbenos un <a class="text-white" href="mailto:restaurantetorreondo@gmail.com" target="_blank"><strong>email</strong></a>.</p>
            <p class="category mt-5" style="text-transform: none !important;">Abrimos todos los días de 07:00 a 22:30 excepto sábado y domingo, de 8:30 a 24:00.</p>

            <p class="category fw-500 mt-3" style="text-transform: none !important;">A 3 minutos andando de la estación de tren de Usansolo y a 10 minutos del hospital.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- <h3 class="title">Mapa</h3> -->
      <iframe
        class="mt-4 mb-4"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11630.518183929578!2d-2.826496754553223!3d43.2172588247558!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4e4bd560500075%3A0x75a680fd1051466c!2sRestaurante%20Torreondo!5e0!3m2!1ses!2ses!4v1682327595111!5m2!1ses!2ses"
        width="100%"
        height="350"
        style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>
<script>

export default {
  name: 'contacto',
  bodyClass: 'profile-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Contacto',
    meta: [
      { name: 'description', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo'},
      { property: 'og:description', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' },
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
};
</script>
<style scoped lang="scss">
  .fw-500 {
    font-weight: 500 !important;
  }
</style>
