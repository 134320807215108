<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/cabeceras/vinos.jpg'); filter: blur(1px); -webkit-filter: blur(1px);"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Carta de vinos</h1>
          <div class="text-center">
            <!-- <h4>gsdgsdg</h4> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="container" style="margin-top: -50px !important;">

        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h3 class="title mb-4">D.O. Rioja</h3>
            <div v-for="rioja in riojas" :key="rioja.id">
              <h5 class="">{{ rioja.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;"><b>{{ rioja.price }}</b></h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title mb-4">Otras Denominaciones de Origen</h4>
            <div v-for="otras_do in otras_dos" :key="otras_do.id">
              <h5 class="">{{ otras_do.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;"><b>{{ otras_do.price }}</b></h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title mb-4">Blancos / Txakolis</h4>
            <div v-for="blanco in blancos" :key="blanco.id">
              <h5 class="">{{ blanco.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;"><b>{{ blanco.price }}</b></h5>
            </div>
          </div>
        </div>
        <hr style="width: 50%;">
        <div class="row" style="margin-top: -25px !important;">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title mb-4">Espumosos</h4>
            <div v-for="espumoso in espumosos" :key="espumoso.id">
              <h5 class="">{{ espumoso.title }}</h5>
              <h5 class="mb-4" style="margin-top: -10px !important;"><b>{{ espumoso.price }}</b></h5>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>
<script>
export default {
  name: 'vinos',
  bodyClass: 'landing-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Carta de vinos',
    meta: [
      { name: 'description', content: 'Siempre con una selecta y exclusiva selección de vinos' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Siempre con una selecta y exclusiva selección de vinos'},
      { property: 'og:description', content: 'Siempre con una selecta y exclusiva selección de vinos' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  data() {
    return {
      riojas: [
        {
          title: 'Luis Cañas (Crianza)',
          price: '19€'
        },
        {
          title: 'Campillo (Crianza)',
          price: '19€'
        },
        {
          title: 'Sierra Cantabria (Crianza)',
          price: '19€'
        },
        {
          title: 'Luis Cañas (Reserva)',
          price: '26€'
        },
        {
          title: 'Ramón Bilbao (Edición limitada)',
          price: '22€'
        },
        {
          title: 'Viña Alberdi',
          price: '21€'
        },
        {
          title: 'Viña Alberdi magnum',
          price: '38€'
        }
      ],
      otras_dos: [
        {
          title: 'Portia (D.O. Ribera de Duero)',
          price: '16€'
        },
        {
          title: 'Bardos (D.O. Ribera de Duero)',
          price: '21€'
        },
        {
          title: 'Habla del Silencio (D.O. Extremadura)',
          price: '21€'
        },
        {
          title: 'Matsu Recio (D.O. Toro)',
          price: '24€'
        },
      ],
      blancos: [
        {
          title: 'Itsasmendi 7 (D.O. Bizkaia)',
          price: '21€'
        },
        {
          title: 'Txakoli (D.O. Getaria)',
          price: '20€'
        },
        {
          title: 'La Ola (Rias Baixas)',
          price: '27€'
        },
        {
          title: 'Envidia Cochina (Rias Baixas)',
          price: '35€'
        },
        {
          title: 'Frore de Carme (Rias Baixas)',
          price: '49€'
        },
      ],
      espumosos: [
        {
          title: 'Juve Camps (D.O. Cava)',
          price: '19€'
        },
        {
          title: 'Recadero (Corpinnat)',
          price: '29€'
        },
        {
          title: 'Aire de Protos Rosé (D.O. Ribera de Duero)',
          price: '15€'
        },
        {
          title: 'Sierra Cantabria Rosé (D.O. Rioja)',
          price: '18€'
        },
        {
          title: 'Champagne',
          price: 'Consultar'
        },
      ]
    };
  }
};
</script>
<style></style>
