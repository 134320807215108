<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/vinos/portada2.jpg'); filter: blur(2.5px); -webkit-filter: blur(2.5px);"
      >
      </parallax>
      <div class="container">
        <h2 class="title" style="margin-top: 300px;text-shadow: 1px 1px #5f85fffe;">PÁGINA NO ENCONTRADA</h2>
        <a
            href="#"
            class="btn btn-primary btn-lg mt-4"
            role="button"
            @click="$router.back()"
          ><strong><i class="fa fa-arrow-left"></i></strong>&nbsp;&nbsp; VOLVER
        </a>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'not-found',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Página no disponible',
    meta: [
      { name: 'description', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo'},
      { property: 'og:description', content: 'Estamos en Pertxin Bidea 7, 48960, Usansolo' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' },
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
};
</script>

<style scoped>
</style>