<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/hamburguesas/portada.jpg'); filter: blur(2px); -webkit-filter: blur(2px);"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">Hamburguesas</h2>
          <div class="text-center">
            <h4>Diseña tu hamburgesa a tu gusto o disfruta de nuestro picoteo y bocadillos</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="container" style="margin-top: -50px !important;">
        <div class="row collections">
          <div class="col-md-12">
            <img src="img/hamburguesas/carta.jpg" class="img-raised mt-3 mb-1" />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-10 ml-auto mr-auto">
          <div class="row collections">
            <div class="col-md-6">
              <img src="img/hamburguesas/1.jpg" class="img-raised mb-4" />
              <img src="img/hamburguesas/2.jpg" class="img-raised mb-4" />
            </div>
            <div class="col-md-6">
              <img src="img/hamburguesas/3.jpg" class="img-raised mb-4" />
              <img src="img/hamburguesas/4.jpg" class="img-raised mb-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hamburguesas',
  bodyClass: 'landing-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Hamburguesas',
    meta: [
      { name: 'description', content: 'Diseña tu hamburgesa a tu gusto o disfruta de nuestro picoteo y bocadillos' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Diseña tu hamburgesa a tu gusto o disfruta de nuestro picoteo y bocadillos'},
      { property: 'og:description', content: 'Diseña tu hamburgesa a tu gusto o disfruta de nuestro picoteo y bocadillos' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  data() {
    return {
      //
    };
  }
};
</script>
<style scoped lang="scss">
</style>
