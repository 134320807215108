<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/cabeceras/menu.jpg')"
      >
      </parallax>
      <div class="container">
        <h2 class="title">Menú fin de semana</h2>
        <p class="category">Sábado y domingo medio día</p>
        
        <div class="content mt-4">
          <div class="social-description">
            <h2>5</h2>
            <p><b>Primeros a elegir</b></p>
          </div>

          <div class="social-description">
            <h2>5</h2>
            <p><b>Segundos a elegir</b></p>
          </div>

          <div class="social-description">
            <h2>y</h2>
            <p class="mt-4"><b>Postres a elegir</b></p>
          </div>

          <div class="mt-4">
            <!-- <p><b>Se incluye pan y agua</b></p> -->
            <h4><b>25€</b>&nbsp; por persona</h4> <p>Pan &nbsp;<b>2€</b>&nbsp; por persona</p>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <h4 class="title text-center">Algunos de nuestros platos</h4>
        </div>
        <tabs
          pills
          class="nav-align-center"
          tab-content-classes="gallery"
          tab-nav-classes="nav-pills-just-icons"
          type="primary"
        >
          <tab-pane title="primeros">
            <!-- <i slot="label" class="now-ui-icons design_image"></i> -->
            <p slot="label" style="font-size: 17px; padding-top: 18px;"><b>Primeros</b></p>

            <div class="col-md-10 ml-auto mr-auto">
              <div class="row collections">
                <div class="col-md-6">
                  <img src="img/m_finde/primeros/1.jpg" class="img-raised" />
                  <img src="img/m_finde/primeros/2.jpg" class="img-raised" />
                </div>
                <div class="col-md-6">
                  <img src="img/m_finde/primeros/3.jpg" class="img-raised" />
                  <img src="img/m_finde/primeros/4.jpg" class="img-raised" />
                  <img src="img/m_finde/primeros/5.jpg" class="img-raised" />
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane title="segundos">
            <!-- <i slot="label" class="now-ui-icons location_world"></i> -->
            <p slot="label" style="font-size: 17px; padding-top: 18px;"><b>Segundos</b></p>

            <div class="col-md-10 ml-auto mr-auto">
              <div class="row collections">
                <div class="col-md-6">
                  <img src="img/m_finde/segundos/1.jpg" class="img-raised" />
                  <img src="img/m_finde/segundos/2.jpg" class="img-raised" />
                </div>
                <div class="col-md-6">
                  <img src="img/m_finde/segundos/3.jpg" class="img-raised" />
                  <img src="img/m_finde/segundos/4.jpg" class="img-raised" />
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane title="postres">
            <!-- <i slot="label" class="now-ui-icons sport_user-run"></i> -->
            <p slot="label" style="font-size: 17px; padding-top: 18px;"><b>Postres</b></p>

            <div class="col-md-10 ml-auto mr-auto">
              <div class="row collections">
                <div class="col-md-6">
                  <img src="img/m_finde/postres/1.jpg" class="img-raised" />
                  <img src="img/m_finde/postres/2.jpg" class="img-raised" />
                </div>
                <div class="col-md-6">
                  <img src="img/m_finde/postres/3.jpg" class="img-raised" />
                  <img src="img/m_finde/postres/4.jpg" class="img-raised" />
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from '@/components';

export default {
  name: 'menu-fin-de-semana',
  bodyClass: 'profile-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | Menú fin de semana en Usansolo',
    meta: [
      { name: 'description', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00'},
      { property: 'og:description', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  components: {
    Tabs,
    TabPane
  }
};
</script>
<style></style>
